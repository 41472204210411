<template>
  <div>

    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Regresar a pantalla anterior" @click="goBack()" v-b-popover.hover.bottom>
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }" class="icon btn-icon" />
          </a>
        </transition>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" @click="openModalFacturas()" v-if="$agregar(['Facturas'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus', }" class="icon btn-icon" /> CARGAR FACTURAS
          </a>
        </transition>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12">
        <b-card no-body>
          <div v-if="loading" class="empty-image center-content">
            <span><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Cargando...</span>
          </div>
          <span v-else>
            <facturas-container :provId="ProvId" :es_proveedor="es_proveedor" :todas="true" :refresh="updateData" @validated="updateScreen($event)"/>
          </span>
        </b-card>
      </div>
    </div>

    <!-- <b-modal
      id="bv-modal-facturas" size="lg" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$agregar(['Facturas'])">
      <template #modal-title>
        Carga de archivos
      </template>
        <upload-box
        v-if="$puede_cargar()"
        @submit="updateScreen($event)"
        :provId="ProvId" />
    </b-modal> -->

  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
// import apiClient from '@/services/axios';
import FacturasContainer from '@/components/FacturasContainer.vue';
// import UploadBox from '@/components/UploadBox.vue';

export default {
  components: {
    FacturasContainer,
    // UploadBox,
  },
  props: [
    'ProvId',
    'PrevRoute',
    // 'NomProv',
    'es_proveedor',
  ],
  data() {
    return {
      loading: false,
      updateData: false,
    };
  },
  mounted() {
  },
  methods: {
    updateScreen(modalMsg = '') {
      // this.$bvModal.hide('bv-modal-facturas');
      this.createModalMsg(modalMsg);
      this.updateData = !this.updateData;
    },
    goBack() {
      this.$router.push({ name: `${this.PrevRoute ? this.PrevRoute : '/dashboard'}` }).catch(() => {});
    },
    createModalMsg(modalMsg) {
      if (modalMsg.length > 0) {
        const h = this.$createElement;
        // Using HTML string
        const msgVNode = h('div', { domProps: { innerHTML: modalMsg } });
        this.$bvModal.msgBoxOk(msgVNode || '', {
          title: 'Atención',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cancelar',
          headerClass: 'text-light',
          bodyClass: 'max-height-70',
          footerClass: 'bg-light p-2',
          hideHeaderClose: true,
          centered: true,
        });
      }
    },
    openModalFacturas() {
      this.$bvModal.show('bv-modal-facturas');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
